import { useRef, useState, useEffect } from 'react';

export const useDebounceValue = <T>(value: T, delay: number) => {
  const timeout = useRef<NodeJS.Timeout>();
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  useEffect(() => {
    timeout.current = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timeout.current);
    };
  }, [value]);

  return debouncedValue;
};
