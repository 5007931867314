import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Account } from '@interfaces/account';

export interface AccountState {
  userAccount?: Account;
  accounts: Account[];

  isLoadingAccounts: boolean;
}

const initialState: AccountState = {
  userAccount: undefined,
  accounts: [],
  isLoadingAccounts: true,
};

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    addAccount: (state, action: PayloadAction<Account>) => {
      return {
        ...state,
        accounts: [...state.accounts, action.payload],
      };
    },
    addStoredAccounts: (state, action: PayloadAction<Account[]>) => {
      return {
        ...state,
        accounts: [...action.payload],
        isLoadingAccounts: false,
      };
    },
    setActiveAccount: (state, action: PayloadAction<Account | undefined>) => {
      return {
        ...state,
        userAccount: action.payload,
      };
    },
  },
});

export const { setActiveAccount, addAccount, addStoredAccounts } =
  accountSlice.actions;

export default accountSlice.reducer;
