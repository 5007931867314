import { View } from 'react-native';
import { useDispatch } from 'react-redux';
import AccountForm from '@components/organisms/AccountForm';
import { addAccount } from '@store/slices/accountSlice';
import { LoginProps } from './types';

const Login = ({ navigation }: LoginProps) => {
  const dispatch = useDispatch();
  return (
    <View style={{ maxWidth: 600, margin: 'auto', width: '100%' }}>
      <AccountForm
        onSuccess={(account) => {
          dispatch(addAccount(account));
          navigation.navigate('selectAccount');
        }}
      />
    </View>
  );
};

export default Login;
