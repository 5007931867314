import { memo } from 'react';
import { View, Text } from 'react-native';
import Button from '@components/atoms/Button';
import { LiveCategoryListItemProps } from './types';

const CategoryListItem = ({
  category,
  handlePress,
  selected = false,
}: LiveCategoryListItemProps) => {
  const background = selected ? 'secondaryDark' : 'secondary';
  return (
    <View>
      <Button background={background} onPress={handlePress} size="full-width">
        {category.categoryName}
      </Button>
    </View>
  );
};

export default memo(CategoryListItem);
