import { View, Text, TextInput } from 'react-native';
import { useState, useEffect } from 'react';
import Input from '@components/atoms/Input';
import { useDebounceValue } from '@utils/hooks/useDebounce';
import { useFuseSearch } from '@utils/hooks/useFuseSearch';
import { useValueAsRef } from '@utils/hooks/useValueAsRef';
import { FilterListProps } from './types';

const FilterList = <T extends {}>({
  data,
  children,
  indexKeys,
  onSearchQueryChange,
  initialSearchQuery = '',
}: FilterListProps<T>) => {
  const [searchQuery, setSearchQuery] = useState<string>(initialSearchQuery);

  const debouncedQuery = useDebounceValue(searchQuery, 200);
  const filteredData = useFuseSearch(
    data,
    indexKeys,
    debouncedQuery,
    Boolean(debouncedQuery)
  );

  const callbackRef = useValueAsRef(onSearchQueryChange);

  useEffect(() => {
    callbackRef?.current?.(debouncedQuery);
  }, [callbackRef, debouncedQuery]);

  return (
    <View style={{ flex: 1 }}>
      <Input
        value={searchQuery}
        onChangeText={setSearchQuery}
        label="Search"
        displayMargin={false}
      />
      {children(filteredData)}
    </View>
  );
};

export default FilterList;
