import { useCallback, useState } from 'react';
import { View, NativeSyntheticEvent, NativeScrollEvent } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/native';
import { LiveCategory } from '@interfaces/liveCategory';
import LiveCategoryList from '@components/organisms/LiveCategoryList';
import Button from '@components/atoms/Button';
import { LiveStream } from '@interfaces/liveStream';
import StreamList from '@components/organisms/StreamList';
import { useGetCategories, useGetCategoryById } from '@utils/hooks/categories';
import { useGetLiveStreamsByCategoryId } from '@utils/hooks/liveStreams';
import { updateScrollPosition } from '@store/slices/categoriesSlice';
import FilterList from '@components/organisms/FilterList';
import FillModal from '@components/molecules/FillModal';
import { RootState } from '@store/store';
import { withAccount } from '@utils/account';
import { useMediaQuery } from 'usehooks-ts';
import { withFocusRender } from '@utils/focus';
import { LiveCategoriesProps } from './types';

const categoryIndexKeys: (keyof LiveCategory)[] = ['categoryName'];
const liveStreamIndexKeys: (keyof LiveStream)[] = ['name'];

const halfWidth = { width: '50%' };

type SidebarViewProps = {
  displayCategoriesInSidebar: boolean;
};

const SidebarView = styled.View<SidebarViewProps>`
  display: flex;
  overflow: hidden;
  flex-shrink: 1;
  flex-grow: ${(props) => (props.displayCategoriesInSidebar ? 0 : 1)};
  flex-direction: ${(props) =>
    props.displayCategoriesInSidebar ? 'row' : 'column'};

  width: ${(props) => {
    if (props.displayCategoriesInSidebar) {
      return '800px';
    }

    return '100%';
  }};
`;

const LiveCategories = ({
  route,
  account,
  navigation: { setParams },
}: LiveCategoriesProps) => {
  const [displayCategoriesModal, setDisplayCategoriesModal] =
    useState<boolean>(false);

  const categorySearch = route?.params?.categorySearch ?? '';
  const selectedCategoryId = route?.params?.selectedCategoryId ?? '-1';

  const scrollPosition = useSelector(
    (state: RootState) => state.categories.scrollPosition
  );

  const displayCategoriesSidebar = useMediaQuery('(min-width: 800px)');

  const initialScrollPosition = selectedCategoryId ? scrollPosition : undefined;

  const {
    data: categories,
    error,
    isLoading,
  } = useGetCategories({ account, type: 'live' });

  const { data: selectedCategories } = useGetCategoryById({
    account,
    type: 'live',
    categoryId: selectedCategoryId,
    enabled: Boolean(selectedCategoryId),
  });

  const selectedCategory = selectedCategories?.[0];

  const dispatch = useDispatch();
  const { data: streams } = useGetLiveStreamsByCategoryId({
    account,
    categoryId: selectedCategoryId,
  });

  const handleScroll = useCallback(
    (event: NativeSyntheticEvent<NativeScrollEvent>) => {
      dispatch(updateScrollPosition(event.nativeEvent.contentOffset.y));
    },
    [dispatch]
  );

  const handleCategorySelected = useCallback(
    (category: LiveCategory) => {
      setParams({ selectedCategoryId: category.categoryId });
      setDisplayCategoriesModal(false);
    },
    [setParams]
  );

  const renderCategories = useCallback(
    (data: LiveCategory[]) => {
      return (
        <LiveCategoryList
          categories={data}
          onScroll={handleScroll}
          initialScrollPosition={initialScrollPosition}
          selectedCategory={selectedCategory}
          onCategorySelect={handleCategorySelected}
        />
      );
    },
    [
      handleScroll,
      initialScrollPosition,
      selectedCategory,
      handleCategorySelected,
    ]
  );

  const renderLiveStreams = useCallback((data: LiveStream[]) => {
    return <StreamList streams={data} />;
  }, []);

  const handleSearchQueryChange = useCallback(
    (query: string) => {
      if (query !== categorySearch) {
        setParams({ categorySearch: query || undefined });
      }
    },
    [setParams, categorySearch]
  );

  const categoriesContent = categories ? (
    <FilterList
      data={categories}
      indexKeys={categoryIndexKeys}
      onSearchQueryChange={handleSearchQueryChange}
      initialSearchQuery={categorySearch}
    >
      {renderCategories}
    </FilterList>
  ) : null;

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        overflow: 'hidden',
        flexShrink: 1,
        flexGrow: 1,
      }}
    >
      <SidebarView displayCategoriesInSidebar={displayCategoriesSidebar}>
        {displayCategoriesSidebar ? (
          <View style={halfWidth}>{categoriesContent}</View>
        ) : (
          <>
            <Button onPress={() => setDisplayCategoriesModal(true)}>
              {selectedCategory?.categoryName
                ? `Category: ${selectedCategory?.categoryName} `
                : 'Select category'}
            </Button>
            <FillModal
              show={displayCategoriesModal}
              onClose={() => setDisplayCategoriesModal(false)}
            >
              {categoriesContent}
            </FillModal>
          </>
        )}

        <View style={displayCategoriesSidebar ? { width: '50%' } : { flex: 1 }}>
          {streams && (
            <FilterList data={streams} indexKeys={liveStreamIndexKeys}>
              {renderLiveStreams}
            </FilterList>
          )}
        </View>
      </SidebarView>
    </View>
  );
};

export default withFocusRender(withAccount(LiveCategories));
