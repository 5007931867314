import { useState, useRef, useEffect } from 'react';
import { View } from 'react-native';
import ReactPlayer from 'react-player';
import { useToast } from 'react-native-toast-notifications';
import { v4 } from 'uuid';
import { VideoPlayerProps } from './types';

const MAX_RETRY_COUNT = 5;

const VideoPlayer = ({ source }: VideoPlayerProps) => {
  const [errorCount, setErrorCount] = useState<number>(0);
  const [key, setKey] = useState<string>(v4());
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [timeoutRef]);

  const toast = useToast();
  return (
    <View style={{ flexGrow: 1, flexShrink: 1, overflow: 'hidden' }}>
      <ReactPlayer
        key={key}
        url={source}
        playing={true}
        style={{ flexGrow: 1, background: '#333' }}
        width="100%"
        height="100%"
        controls={true}
        onPlay={() => {
          setErrorCount(0);
        }}
        onError={(error, data, hlsInstance, hlsGlobal) => {
          console.error(error);
          /**
           * Get an initial call to this fn with an empty error. Assuming this is a dummy error
           */
          const isDummyError =
            data === undefined &&
            hlsInstance === undefined &&
            hlsGlobal === undefined;

          if (isDummyError) return;

          // Already retrying
          if (timeoutRef.current) return;

          if (errorCount === MAX_RETRY_COUNT - 1) {
            toast.show('Failed to load video', {
              type: 'danger',
              placement: 'top',
            });
            return;
          }

          toast.show(
            `Failed to load video. Retrying (${
              errorCount + 1
            }/${MAX_RETRY_COUNT})`,
            { type: 'danger', placement: 'top' }
          );
          setErrorCount((count) => count + 1);
          timeoutRef.current = setTimeout(() => {
            timeoutRef.current = undefined;
            setKey(v4());
          }, 7000);
        }}
      />
    </View>
  );
};

export default VideoPlayer;
