import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  getM3U8Playlist,
  transformM3U8PlaylistToLiveStreams,
} from '@utils/m3u8';
import { transformXtreamStreamToLiveStreams } from '@utils/xtream';
import { getXtreamLiveStream } from '@requests/xtream/stream';
import { Account } from '@interfaces/account';
import { LiveStream } from '@interfaces/liveStream';

const ONE_MINUTE_MS = 60 * 1000;
const ONE_HOUR_MS = ONE_MINUTE_MS * 60;
const ONE_DAY_MS = ONE_HOUR_MS * 24;

interface UseGetLiveStreamsArgs {
  account: Account;
  options?: Omit<
    UseQueryOptions<LiveStream[], unknown, LiveStream[], string[]>,
    'initialData' | 'queryFn' | 'queryKey'
  > & { initialData?: (() => undefined) | undefined };
}

export const useGetLiveStreams = ({
  account,
  options,
}: UseGetLiveStreamsArgs) => {
  return useQuery(
    ['live-streams', account.id],
    () => {
      if (account?.m3u8) {
        return getM3U8Playlist(account?.m3u8.playlistUrl).then((playlist) => {
          return transformM3U8PlaylistToLiveStreams(playlist);
        });
      }

      if (account?.xtream) {
        const { url, username, password } = account?.xtream;
        return getXtreamLiveStream(url, username, password).then((streams) => {
          // return transformXtreamStreamToLiveStreams(streams);
          return streams.map((stream) => {
            return transformXtreamStreamToLiveStreams(
              url,
              username,
              password,
              stream
            );
          });
        });
      }
      return [];
    },
    {
      staleTime: ONE_DAY_MS,
      ...options,
    }
  );
};

export const useGetLiveStreamById = (
  account: Account,
  streamId: string | number
) => {
  return useGetLiveStreams({
    account,
    options: {
      select: (data) => {
        const item = data.find((stream) => stream.id === streamId);
        if (item) return [item];
        return [];
      },
    },
  });
};

interface UseGetLiveStreamsByCategoryIdArgs {
  account: Account;
  categoryId?: string;
}

export const useGetLiveStreamsByCategoryId = ({
  account,
  categoryId,
}: UseGetLiveStreamsByCategoryIdArgs) => {
  return useGetLiveStreams({
    account,
    options: {
      select: (data) => {
        if (!categoryId) {
          return data;
        }

        if (categoryId === '-1') {
          return data;
        }

        return data.filter((stream) => {
          return stream.categoryIds.includes(categoryId);
        });
      },
    },
  });
};
