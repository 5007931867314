import { z } from 'zod';

export const XtreamAccountInfoSchema = z.object({
  user_info: z.object({
    username: z.string(),
    password: z.string(),
    message: z.string(),
    auth: z.number(),
    status: z.string(),
    exp_date: z.string(),
    is_trial: z.string(),
    active_cons: z.number(),
    created_at: z.string(),
    max_connections: z.string(),
    allowed_output_formats: z.array(z.string()),
  }),
  server_info: z.object({
    xcms: z.boolean(),
    version: z.string(),
    revision: z.null(),
    url: z.string(),
    port: z.string(),
    https_port: z.string(),
    server_protocol: z.string(),
    rtmp_port: z.string(),
    timestamp_now: z.number(),
    time_now: z.string(),
    timezone: z.string(),
  }),
});

export type XtreamAccountInfo = z.infer<typeof XtreamAccountInfoSchema>;
