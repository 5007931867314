import { ComponentProps } from 'react';
import { z } from 'zod';
import { useToast } from 'react-native-toast-notifications';
import { useForm, Controller, FieldValues, Path, UseFormRegister, SubmitHandler } from 'react-hook-form';
import { zodResolver } from "@hookform/resolvers/zod";
import styled from 'styled-components/native';
import Input, { InputProps } from '@components/atoms/Input';
import Button from '@components/atoms/Button';
import { getXtreamAccountInfo } from '@requests/xtream/account';
import { transformXtreamAccountInfoToAccount } from '@utils/xtream';
import { AccountFormValues } from '@interfaces/account';
import { AccountFormProps } from './types';

const StyledView = styled.View`
  align-items: flex-end ;
`


const defaultValues = {
  type: 'xtream',
  xtream: {
    username: '',
    password: '',
    url: '',
  }
}


type RenderFieldProps<TFieldValues extends FieldValues, TName extends Path<TFieldValues>> = Parameters<
  ComponentProps<typeof Controller<TFieldValues, TName>>['render']
>[0];

const renderField = <TFieldValues extends FieldValues, TName extends Path<TFieldValues>>(register: UseFormRegister<TFieldValues>, props?: Partial<InputProps>) => {
  return ({
    field: { onChange, value, name },
  }: RenderFieldProps<TFieldValues, TName>) => {
    return <Input {...props} onChangeText={onChange} value={value} label={props?.label ?? name} {...register(name)} />;
  };
}


// NOTE: Look at z.refine to handle values based on other fields?
const FormSchema = z.object({
  type: z.string(),
  xtream: z.object({
    username: z.string().min(1),
    password: z.string().min(1),
    url: z.string().url(),
  })
})


const AccountForm = ({ onSuccess }: AccountFormProps) => {
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: zodResolver(FormSchema),
  });

  const toast = useToast();

  const onSubmit: SubmitHandler<AccountFormValues> = async (values) => {
    const { xtream: { url, username, password }  } = values

    try {
      const xtreamAccount = await getXtreamAccountInfo({
        url,
        username,
        password,
      })

      const account = transformXtreamAccountInfoToAccount(values, xtreamAccount)
      onSuccess?.(account)
    } catch (error) {
      console.error(error)

      // TODO: try giving more useful error
      toast.show('Unknown error', { type: 'danger' })
    }
  }

  return (
    <StyledView>
      <Controller control={control} name="xtream.username" render={renderField(register, { label: 'Username', error: errors?.xtream?.username?.message })} />
      <Controller control={control} name="xtream.password" render={renderField(register, { secureTextEntry: true, label: 'Password', error: errors?.xtream?.password?.message })} />
      <Controller control={control} name="xtream.url" render={renderField(register, { label: 'Url', error: errors?.xtream?.url?.message })} />

      <Button onPress={handleSubmit(onSubmit)} size="small">Login</Button>
    </StyledView>
  );
}

export default AccountForm
