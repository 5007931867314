import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { createStackNavigator } from '@react-navigation/stack';
import { useTheme } from 'styled-components/native';
import { NavigationLiveParamList } from '@interfaces/routes';
import LiveCategories from '@components/views/LiveCategories';
import VideoScreen from '@components/views/VideoScreen';
import { withAccount } from '@utils/account';
import { LiveProps } from './types';

const Stack = createStackNavigator<NavigationLiveParamList>();

const Live = (props: LiveProps) => {
  const theme = useTheme();
  return (
    <>
      <Stack.Navigator
        initialRouteName="categories"
        detachInactiveScreens={true}
        screenOptions={{
          cardStyle: {
            backgroundColor: theme.bgColor,
          },
          headerShown: false,
        }}
      >
        <Stack.Screen name="categories" component={LiveCategories} />
        <Stack.Screen name="video" component={VideoScreen} />
      </Stack.Navigator>
    </>
  );
};

export default withAccount(Live);
