import { XtreamCategoryListSchema } from '@interfaces/xtream/category';

export const getXtreamLiveStreamCategory = async (
  url: string,
  username: string,
  password: string
) => {
  const json = await fetch(
    `${url}/player_api.php?username=${username}&password=${password}&action=get_live_categories`
  ).then((res) => res.json());

  return XtreamCategoryListSchema.parse(json);
};
