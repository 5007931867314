import {
  View,
  Text,
  StyleProp,
  ViewStyle,
  TouchableOpacity,
} from 'react-native';
import { AccountSelectionProps } from './types';

const AccountSelection = ({
  name,
  subtext,
  onPress,
}: AccountSelectionProps) => {
  const styles: StyleProp<ViewStyle> = {
    display: 'flex',
    width: 200,
    height: 200,
    backgroundColor: '#efefef',
    padding: 15,
    overflow: 'hidden',
    marginHorizontal: 15,
  };

  return (
    <TouchableOpacity style={styles} onPress={onPress}>
      <View
        style={{
          width: '100%',
          height: 120,
          backgroundColor: '#d9d9d9',
          marginBottom: 15,
        }}
      />
      <Text>{name}</Text>
      <Text>{subtext}</Text>
    </TouchableOpacity>
  );
};

export default AccountSelection;
