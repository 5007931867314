import { DeviceType, getDeviceTypeAsync } from 'expo-device';
import { createSlice } from '@reduxjs/toolkit';

export interface ConfigState {
  deviceType: DeviceType;
}

export const createConfigSlice = async () => {
  const initialState: ConfigState = {
    deviceType: await getDeviceTypeAsync(),
  };

  const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {},
  });

  return configSlice.reducer;
};
