import { z } from 'zod';

export const AccountSchema = z.object({
  id: z.string(),
  name: z.string().optional(),
  m3u8: z
    .object({
      playlistUrl: z.string(),
    })
    .optional(),
  xtream: z
    .object({
      username: z.string(),
      password: z.string(),
      url: z.string(),
    })
    .optional(),
});

export const AccountArraySchema = z.array(AccountSchema);

export type Account = z.infer<typeof AccountSchema>;

export type AccountFormValues = {
  type: string;
  xtream: {
    username: string;
    password: string;
    url: string;
  };
};
