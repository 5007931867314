import { View } from 'react-native';
import { StatusBar } from 'expo-status-bar';
import Button from '@components/atoms/Button';
import styled from 'styled-components/native';
import VideoPlayer from '@components/organisms/VideoPlayer/VideoPlayer';
import { useGetLiveStreamById } from '@utils/hooks/liveStreams';
import { withAccount } from '@utils/account';
import { withFocusRender } from '@utils/focus';
import { VideoScreenProps } from './types';

const VideoScreenView = styled.View`
  flex: 1;
  background: #000;
  display: flex;
  justify-content: center;
`;

const VideoHeaderView = styled.View`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
`;

const VideoScreen = ({ account, route, navigation }: VideoScreenProps) => {
  const { streamId } = route.params;

  const { data: streams } = useGetLiveStreamById(account, streamId);
  const stream = streams?.[0];

  const handleGoBack = () => {
    const navState = navigation.getState();

    const previousRoute = navState.routes[navState.index - 1];
    if (previousRoute && previousRoute.name === 'categories') {
      navigation.goBack();
    } else {
      navigation.navigate('categories', {});
    }
  };

  return (
    <VideoScreenView>
      <StatusBar hidden={true} />
      <VideoHeaderView>
        <Button onPress={handleGoBack} size="small">
          Go back
        </Button>
      </VideoHeaderView>
      {stream && <VideoPlayer source={stream.source} />}
    </VideoScreenView>
  );
};

export default withFocusRender(withAccount(VideoScreen));
