import { XtreamStreamSchema } from '@interfaces/xtream/stream';

export const getXtreamLiveStream = async (
  url: string,
  username: string,
  password: string
) => {
  const json = await fetch(
    `${url}/player_api.php?username=${username}&password=${password}&action=get_live_streams`
  ).then((res) => res.json());

  if (Array.isArray(json)) {
    const xtreamStreams = json.map((val) => {
      return XtreamStreamSchema.parse(val);
    });

    return xtreamStreams;
  }

  throw new Error('NO_STREAMS');
};
