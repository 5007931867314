import { configureStore } from '@reduxjs/toolkit';
import categoriesReducer from './slices/categoriesSlice';
import accountReducer from './slices/accountSlice';
import { createConfigSlice } from './slices/configSlice';

export const createStore = async () => {
  const configReducer = await createConfigSlice();
  const store = configureStore({
    reducer: {
      categories: categoriesReducer,
      account: accountReducer,
      config: configReducer,
    },
  });

  return store;
};

type Store = Awaited<ReturnType<typeof createStore>>;

export type RootState = ReturnType<Store['getState']>;
export type AppDispatch = Store['dispatch'];
