import { forwardRef, Ref, ComponentProps } from 'react';
import { TextInput } from 'react-native';
import styled from 'styled-components/native';
import { InputProps } from './types';

type StyledViewProps = {
  displayMargin?: boolean;
};

const StyledTextInput = styled.TextInput`
  padding: 10px 20px;
  font-size: 16px;
  border: 1px solid ${(props) => props.theme.borderColor1};
  color: ${(props) => props.theme.textDefault};
  width: 100%;
`;

const StyledView = styled.View<StyledViewProps>`
  width: 100%;
  margin-bottom: ${(props) => (props.displayMargin ? '30px' : '0')};
`;

const StyledText = styled.Text`
  font-size: 16px;
  margin-bottom: 10px;
  color: ${(props) => props.theme.textDefault};
`;

const StyledErrorText = styled.Text`
  font-size: 14px;
  margin-bottom: 10px;
  color: ${(props) => props.theme.error};
`;

const Input = forwardRef<TextInput, InputProps>(
  ({ id, label, error, displayMargin = true, ...textInputProps }, ref) => {
    return (
      <StyledView displayMargin={displayMargin}>
        <StyledText>{label}</StyledText>
        <StyledTextInput {...textInputProps} ref={ref} nativeID={id} />
        {Boolean(error) && <StyledErrorText>{error}</StyledErrorText>}
      </StyledView>
    );
  }
);

export default Input;
