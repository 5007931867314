import { useEffect, JSXElementConstructor } from 'react';
import { useSelector } from 'react-redux';
import { useNavigation, NavigationProp } from '@react-navigation/native';
import { DrawerNavigationProp } from '@react-navigation/drawer';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { NavigationDrawerParamList } from '@interfaces/routes';
import { Account } from '@interfaces/account';
import { RootState } from '@store/store';

/**
 * HOC that passes the selected account into the component
 */
export const withAccount = <TProps extends { account: Account }>(
  Component: JSXElementConstructor<TProps>
) => {
  return (props: Omit<TProps, 'account'>) => {
    const account = useSelector(
      (state: RootState) => state.account.userAccount
    );
    const { navigate } =
      useNavigation<NavigationProp<NavigationDrawerParamList>>();

    useEffect(() => {
      if (!account) {
        navigate('selectAccount');
      }
    }, [account, navigate]);

    if (!account) {
      return null;
    }

    const newProps = {
      ...props,
      account,
    } as TProps;

    return <Component {...newProps} />;
  };
};
