import { useCallback, memo, useRef, useMemo, useState } from 'react';
import { View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import {
  RecyclerListView,
  DataProvider,
  LayoutProvider,
} from 'recyclerlistview';
import { LiveCategory } from '@interfaces/liveCategory';
import { useValueAsRef } from '@utils/hooks/useValueAsRef';
import CategoryListItem from '@components/molecules/LiveCategoryListItem';
import { LiveCategoryListProps } from './types';

const CategoryList = ({
  categories,
  onScroll,
  initialScrollPosition,
  onCategorySelect,
  selectedCategory,
}: LiveCategoryListProps) => {
  const [viewWidth, setViewWidth] = useState(400);
  const viewWidthRef = useValueAsRef(viewWidth);

  const [layoutProvider] = useState(
    new LayoutProvider(
      (i) => {
        return 'LAYOUT';
      },
      (type, dim) => {
        dim.width = viewWidthRef.current;
        dim.height = 40;
      }
    )
  );

  const isScrollSetRef = useRef<boolean>(false);

  const selectedCategoryId = selectedCategory?.categoryId;

  const rowRenderer = useCallback(
    (type: string | number, category: LiveCategory) => {
      const handlePress = () => {
        onCategorySelect?.(category);
      };
      return (
        <CategoryListItem
          handlePress={handlePress}
          category={category}
          key={category.categoryId}
          selected={category.categoryId === selectedCategoryId}
        />
      );
    },
    [selectedCategoryId, onCategorySelect]
  );

  // Makes sure selectedCategories are always displayed in the list
  const preparedCategories = useMemo(() => {
    if (
      selectedCategory &&
      categories.find(
        (cat) => cat.categoryId === selectedCategory.categoryId
      ) === undefined
    ) {
      return [selectedCategory, ...categories];
    }
    return categories;
  }, [selectedCategory, categories]);

  const dataProvider = useMemo(() => {
    return new DataProvider((r1, r2) => {
      return r1 !== r2;
    }).cloneWithRows(preparedCategories);
  }, [preparedCategories]);

  const extendedState = useMemo(() => {
    return { selectedCategoryId };
  }, [selectedCategoryId]);

  return (
    <View
      style={{ width: '100%', flexShrink: 1, flexGrow: 1 }}
      onLayout={(event) => {
        const { width } = event.nativeEvent.layout;
        setViewWidth(width);
      }}
    >
      <RecyclerListView
        style={{ flex: 1 }}
        dataProvider={dataProvider}
        layoutProvider={layoutProvider}
        rowRenderer={rowRenderer}
        extendedState={extendedState}
        layoutSize={{ width: viewWidth, height: 40 }}
        canChangeSize={true}
      />
    </View>
  );
};

export default memo(CategoryList);
