import { JSXElementConstructor } from 'react';
import { useIsFocused } from '@react-navigation/native';

/**
 * HOC that doesn't render component unless route is focused
 */
export const withFocusRender = <TProps extends {}>(
  Component: JSXElementConstructor<TProps>
) => {
  return (props: TProps) => {
    const isFocused = useIsFocused();

    if (!isFocused) {
      return null;
    }

    return <Component {...props} />;
  };
};
