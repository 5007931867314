import { z } from 'zod';

export const XtreamCategorySchema = z.object({
  category_id: z.string(),
  category_name: z.string(),
  parent_id: z.number(),
});

export type XtreamCategory = z.infer<typeof XtreamCategorySchema>;

export const XtreamCategoryListSchema = z.array(XtreamCategorySchema);
