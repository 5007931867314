import styled from 'styled-components/native';
import Button from '@components/atoms/Button';
import { hexToRGB } from '@utils/color';

import { FillModalProps } from './types';

const StyledFillModalView = styled.View`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: ${(props) => hexToRGB(props.theme.bgColorDark, 0.9)};
  z-index: 10;
  padding: 15px;
`;

// NOTE: Update name on this
const FillModal = ({ children, onClose, show = false }: FillModalProps) => {
  return show ? (
    <StyledFillModalView>
      <Button size="small" onPress={() => onClose?.()}>
        Close
      </Button>
      {children}
    </StyledFillModalView>
  ) : null;
};

export default FillModal;
