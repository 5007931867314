import {
  NavigationContainer,
  getFocusedRouteNameFromRoute,
  RouteProp,
} from '@react-navigation/native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { useTheme } from 'styled-components/native';
import { useSelector } from 'react-redux';
import { RootState } from '@store/store';
import Login from '@components/views/Login';
import Live from '@components/views/Live';
import SelectAccount from '@components/views/SelectAccount';
import { NavigationDrawerParamList } from '@interfaces/routes';

const Drawer = createDrawerNavigator<NavigationDrawerParamList>();

const getHeaderShown = (
  route: RouteProp<NavigationDrawerParamList, 'live'>
) => {
  const routeName = getFocusedRouteNameFromRoute(route);
  if (routeName === 'video') {
    return false;
  }

  return true;
};

const Routes = () => {
  const theme = useTheme();
  const selectedAccount = useSelector(
    (state: RootState) => state.account.userAccount
  );

  return (
    <NavigationContainer
      linking={{
        enabled: true,
        prefixes: ['https://example.com', 'example://'],
      }}
    >
      {/* <LinearGradient
              colors={['#141E30', '#243B55']}
              start={[0, 1]}
              end={[1, 0]}
              style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              }}
            /> */}
      <Drawer.Navigator
        initialRouteName="selectAccount"
        screenOptions={{
          // drawerType: dimensions.width >= 768 ? 'permanent' : 'slide',
          drawerType: 'front',
          sceneContainerStyle: {
            backgroundColor: theme.bgColor,
          },
          headerStyle: {
            backgroundColor: theme.bgColor,
            borderBottomWidth: 0,
            shadowColor: 'none',
          },
          headerTintColor: theme.textDefault,

          headerShown: true,
          unmountOnBlur: true,
        }}
      >
        <Drawer.Screen
          name="login"
          component={Login}
          options={{
            drawerItemStyle: {
              display: 'none',
            },
          }}
        />
        {selectedAccount && (
          <Drawer.Screen
            name="live"
            component={Live}
            options={(props) => {
              return {
                title: 'Live TV',
                headerShown: getHeaderShown(props.route),
              };
            }}
          />
        )}
        <Drawer.Screen
          name="selectAccount"
          component={SelectAccount}
          options={{ title: 'Select Account ' }}
        />
      </Drawer.Navigator>
    </NavigationContainer>
  );
};

export default Routes;
