import { parse, Playlist } from 'iptv-playlist-parser';
import { LiveStream } from '@interfaces/liveStream';

/**
 * Fetches a m3u8 playlist and parses it
 */
export const getM3U8Playlist = (src: string) => {
  return fetch(src)
    .then((response) => response.text())
    .then((response) => {
      const playlist = parse(response);
      return playlist;
    });
};

/**
 * Transforms a m3u8 playlist into live streams interface used internally
 */
export const transformM3U8PlaylistToLiveStreams = (
  playlist: Playlist
): LiveStream[] => {
  const streamsMap = new Map<string, LiveStream>();
  playlist.items.forEach((playlistItem) => {
    // Fallback to url if id is an empty string
    const id = playlistItem.tvg.id || playlistItem.url;
    const streamMapValue = streamsMap.get(id);
    if (streamMapValue) {
      console.warn(`Merging stream with id of ${id}`);
      streamMapValue.altSources.push(playlistItem.url);
    } else {
      const item = {
        id,
        name: playlistItem.name,
        source: playlistItem.url,
        altSources: [],
        categoryIds: [],
      };

      streamsMap.set(id, item);
    }
  });

  return [...streamsMap.values()];
};
