import { useState } from 'react';
import { registerRootComponent } from 'expo';
import { Provider } from 'react-redux';
import { NativeBaseProvider } from 'native-base';
import { QueryClient } from '@tanstack/react-query';
import { Store } from '@reduxjs/toolkit';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { useEffectOnce } from 'usehooks-ts';
import { createStore } from '@store/store';
import AccountStorage from '@components/atoms/AccountStorage';
import { ThemeProvider } from 'styled-components/native';
import { ToastProvider } from 'react-native-toast-notifications';
import { defaultTheme } from '@themes/default';
import Routes from './Routes';
import 'react-native-gesture-handler';
import '@expo/match-media';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

export default function App() {
  const [store, setStore] = useState<Store>();

  useEffectOnce(() => {
    (async () => {
      try {
        const store = await createStore();
        setStore(store);
      } catch (error) {
        console.error(error);
      }
    })();
  });
  return store ? (
    <ThemeProvider theme={defaultTheme}>
      <ToastProvider>
        <PersistQueryClientProvider
          client={queryClient}
          persistOptions={{
            persister,
            dehydrateOptions: {
              shouldDehydrateQuery: (query) => {
                // Below will stop queries being persisted to disk
                // if (query.queryKey.includes('DISABLE')) {
                //   return false;
                // }
                return true;
              },
            },
          }}
        >
          <Provider store={store}>
            <AccountStorage />
            <NativeBaseProvider>
              <Routes />
            </NativeBaseProvider>
          </Provider>
        </PersistQueryClientProvider>
      </ToastProvider>
    </ThemeProvider>
  ) : null;
}

/**
 * @see https://docs.expo.dev/versions/latest/sdk/register-root-component/#what-if-i-want-to-name-my
 */
registerRootComponent(App);
