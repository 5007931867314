import { useEffect } from 'react';
import { Platform } from 'react-native';
import { useEffectOnce } from 'usehooks-ts';
import { setItemAsync, getItemAsync, deleteItemAsync } from 'expo-secure-store';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@store/store';
import { Account, AccountArraySchema } from '@interfaces/account';
import {
  addStoredAccounts,
  setActiveAccount,
} from '@store/slices/accountSlice';
import { AccountStorageProps } from './types';

const getItem = async (key: string) => {
  if (Platform.OS === 'web') {
    return localStorage.getItem(key);
  }
  return getItemAsync(key);
};

const setItem = async (key: string, value: string) => {
  if (Platform.OS === 'web') {
    return localStorage.setItem(key, value);
  }
  return setItemAsync(key, value);
};

const removeItem = async (key: string) => {
  if (Platform.OS === 'web') {
    return localStorage.removeItem(key);
  }
  return deleteItemAsync(key);
};

const loadAccounts = async () => {
  try {
    const jsonAccounts = await getItem('ACCOUNTS');
    if (jsonAccounts) {
      const possibleAccounts = JSON.parse(jsonAccounts);
      const accounts = AccountArraySchema.parse(possibleAccounts);
      return accounts;
    }
  } catch (error) {
    console.error(error);
  }

  return [];
};

const loadSelectedAccount = async (accounts: Account[]) => {
  try {
    const selectedAccountId = await getItem('SELECTED_ACCOUNT');
    if (selectedAccountId) {
      const account = accounts.find(
        (account) => account.id === selectedAccountId
      );
      if (account) {
        return account;
      }
    }
  } catch (error) {
    console.log(error);
  }

  return undefined;
};

/**
 * Temporary solution for storing & loading account info
 */
const AccountStorage = (props: AccountStorageProps) => {
  const accounts = useSelector((state: RootState) => state.account.accounts);
  const dispatch = useDispatch();
  const selectedAccount = useSelector(
    (state: RootState) => state.account.userAccount
  );

  const selectedAccountId = selectedAccount?.id;

  // Fills store with data
  useEffectOnce(() => {
    (async () => {
      const accounts = await loadAccounts();
      const activeAccount = await loadSelectedAccount(accounts);
      dispatch(addStoredAccounts(accounts));
      dispatch(setActiveAccount(activeAccount));
    })();
  });

  useEffect(() => {
    (async () => {
      try {
        await setItem('ACCOUNTS', JSON.stringify(accounts));
      } catch (error) {
        console.error(error);
      }
    })();
  }, [accounts]);

  useEffect(() => {
    (async () => {
      try {
        if (selectedAccountId) {
          await setItem('SELECTED_ACCOUNT', selectedAccountId);
        } else {
          await removeItem('SELECTED_ACCOUNT');
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [selectedAccountId]);

  return null;
};

export default AccountStorage;
