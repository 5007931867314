import { memo } from 'react';
import { View } from 'react-native';
import Button from '@components/atoms/Button';
import { StreamListItemProps } from './types';

const StreamListItem = ({ stream, handlePress }: StreamListItemProps) => {
  const boxStyles = {
    width: '100%',
  };

  return (
    <View style={boxStyles}>
      <Button onPress={handlePress} background="secondary" size="full-width">
        {stream.name}
      </Button>
    </View>
  );
};

export default memo(StreamListItem);
