import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CategoriesState {
  scrollPosition?: number;
}

const initialState: CategoriesState = {
  scrollPosition: undefined,
};

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    updateScrollPosition: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        scrollPosition: action.payload,
      };
    },
  },
});

export const { updateScrollPosition } = categoriesSlice.actions;

export default categoriesSlice.reducer;
