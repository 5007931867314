import { useMemo } from 'react';
import Fuse from 'fuse.js';

export const useFuseSearch = <T extends {}>(
  items: T[],
  indexKeys: Fuse.FuseOptionKey<T>[],
  searchQuery: string,
  enabled: boolean = true
) => {
  const fuse = useMemo(() => {
    return new Fuse<T>(items, {
      keys: indexKeys,
      threshold: 0.3,
    });
  }, [items, indexKeys]);

  return useMemo(() => {
    if (enabled) {
      const result = fuse.search(searchQuery);
      return result.map((res) => res.item);
    }
    return items;
  }, [fuse, enabled, searchQuery]);
};
