import axios from 'axios';
import { XtreamAccountInfoSchema } from '@interfaces/xtream/account';

type GetXtreamAccountInfoArgs = {
  url: string;
  username: string;
  password: string;
};

export const getXtreamAccountInfo = async ({
  url,
  username,
  password,
}: GetXtreamAccountInfoArgs) => {
  const response = await axios.get(
    `${url}/player_api.php?username=${username}&password=${password}`
  );

  const info = XtreamAccountInfoSchema.parse(response.data);
  return info;
};
