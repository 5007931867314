import { DefaultTheme } from 'styled-components/native';

export const defaultTheme: DefaultTheme = {
  bgColor: '#282D4F',
  bgColorDark: '#1F243E',

  primary: '#ff6c00',
  // secondary: '#522546',
  secondary: '#23103A',
  secondaryDark: '#1B0D2E',
  error: '#91171f',

  textDefault: '#ffffff',

  borderColor1: '#fff',
};
