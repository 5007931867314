import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/native';
import Button from '@components/atoms/Button';
import AccountSelection from '@components/atoms/AccountSelection';
import { RootState } from '@store/store';
import { setActiveAccount } from '@store/slices/accountSlice';
import { SelectAccountProps } from './types';

const StyledRootView = styled.View`
  align-items: center;
`;

const SelectAccount = ({ navigation }: SelectAccountProps) => {
  const dispatch = useDispatch();
  const accounts = useSelector((state: RootState) => state.account.accounts);

  return (
    <StyledRootView>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          padding: 50,
          justifyContent: 'center',
        }}
      >
        {accounts.map((account) => {
          const { id } = account;
          const playlistUrl = account?.m3u8?.playlistUrl ?? '';
          return (
            <AccountSelection
              key={id}
              name={account.name ?? ''}
              subtext={playlistUrl}
              onPress={() => {
                dispatch(setActiveAccount(account));
                if (account.xtream) {
                  // in setTimeout so route is available to navigate to from an account being selected
                  setTimeout(() => {
                    navigation.navigate('live', {
                      screen: 'categories',
                      params: {},
                    });
                  }, 0);
                } else {
                  // navigation.navigate('liveStreams', {});
                }
              }}
            />
          );
        })}
      </View>
      <Button
        onPress={() => {
          navigation.navigate('login');
        }}
      >
        Add New Account
      </Button>
    </StyledRootView>
  );
};

export default SelectAccount;
