import { Pressable, Text, PressableProps } from 'react-native';
import styled from 'styled-components/native';
import { ButtonProps } from './types';

type StyledPressableProps = PressableProps &
  Required<Pick<ButtonProps, 'background' | 'size'>>;

const StyledPressable = styled.Pressable<StyledPressableProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 10px;
  background: ${(props) => props.theme[props.background]};
  border: 1px solid ${(props) => props.theme.secondaryDark};
  width: 100%;
  max-width: ${(props) => {
    switch (props.size) {
      case 'full-width':
        return '100%';

      case 'small':
        return '150px';

      default:
      case 'medium':
        return '300px';
    }
  }};
`;

const StyledText = styled.Text`
  color: ${(props) => props.theme.textDefault};
`;

const Button = ({
  onPress,
  children = '',
  background = 'primary',
  size = 'medium',
}: /* background = ButtonBackground.PRIMARY, */
ButtonProps) => {
  return (
    <StyledPressable
      onPress={onPress}
      background={background}
      size={size}
      // style={{
      //   height: 40,
      //   backgroundColor: buttonBackground,
      //   display: 'flex',
      //   alignItems: 'center',
      //   justifyContent: 'center',
      // }}
    >
      <StyledText>{children}</StyledText>
    </StyledPressable>
  );
};

export default Button;
