import { XtreamStream } from '@interfaces/xtream/stream';
import { XtreamCategory } from '@interfaces/xtream/category';
import { XtreamAccountInfo } from '@interfaces/xtream/account';
import { LiveStream } from '@interfaces/liveStream';
import { AccountFormValues } from '@interfaces/account';
import { Account } from '@interfaces/account';
import { LiveCategory } from '@interfaces/liveCategory';

export const transformXtreamStreamToLiveStreams = (
  url: string,
  username: string,
  password: string,
  stream: XtreamStream
): LiveStream => {
  return {
    id: stream.stream_id.toString(),
    name: stream.name,
    /**
     * LIVE
     * (seems you can exclude live from the url)
     * <url>/<username>/<password>/<streamId>.ts
     * <url>/<username>/<password>/<streamId>.m3u8 - HLS
     *
     * SERIES
     * id & container_extension are from action=get_series_info&series_id=x
     * <url>/series/<username>/<password>/<id>.<container_extension>
     *
     * MOVIES
     * stream_id & container_extension from action=get_vod_streams
     * <url>/movie/<username>/<password>/<stream_id>.<container_extension>
     */
    source: `${url}/live/${username}/${password}/${stream.stream_id}.m3u8`,
    altSources: [],
    categoryIds: stream.category_ids.map((id) => id.toString()),
  };
};

export const transformXtreamCategoryToCategory = (
  category: XtreamCategory
): LiveCategory => {
  return {
    categoryId: category.category_id,
    categoryName: category.category_name,
    parentCategoryId: Boolean(category.parent_id)
      ? category.parent_id.toString()
      : undefined,
  };
};

export const transformXtreamAccountInfoToAccount = (
  { xtream: { url, username, password } }: AccountFormValues,
  { user_info, server_info }: XtreamAccountInfo
): Account => {
  const id = `${server_info.url}/${user_info.username}`;
  return {
    id,
    name: `${server_info.url}`,
    xtream: {
      url,
      password,
      username,
    },
  };
};
