import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { Account } from '@interfaces/account';
import { LiveCategory } from '@interfaces/liveCategory';
import { getXtreamLiveStreamCategory } from '@requests/xtream/category';
import { transformXtreamCategoryToCategory } from '@utils/xtream';

type CategoriesType = 'live' | 'vod' | 'series';

const ONE_MINUTE_MS = 60 * 1000;
const ONE_HOUR_MS = ONE_MINUTE_MS * 60;
const ONE_DAY_MS = ONE_HOUR_MS * 24;

interface UseGetCategoriesArgs {
  account: Account;
  type: CategoriesType;
  options?: UseQueryOptions<LiveCategory[], unknown, LiveCategory[], string[]>;
}

export const useGetCategories = ({
  account,
  type,
  options,
}: UseGetCategoriesArgs) => {
  return useQuery(
    [account.id, 'categories', type],
    async () => {
      if (account?.xtream) {
        const { url, username, password } = account?.xtream;
        const categories = await getXtreamLiveStreamCategory(
          url,
          username,
          password
        );
        const formattedCategories = categories.map((cat) =>
          transformXtreamCategoryToCategory(cat)
        );
        return [
          {
            categoryName: 'All Channels',
            categoryId: '-1',
            parentCategoryId: undefined,
          },
          ...formattedCategories,
        ];
      }

      return [];
    },
    {
      staleTime: ONE_DAY_MS,
      ...options,
    }
  );
};

interface UseGetCategoryByIdArgs {
  account: Account;
  type: CategoriesType;
  categoryId?: string;
  enabled: boolean;
}

export const useGetCategoryById = ({
  account,
  type,
  categoryId,
  enabled = true,
}: UseGetCategoryByIdArgs) => {
  return useGetCategories({
    type,
    account,
    options: {
      enabled,
      select: (data) => {
        const result = data.find((cat) => cat.categoryId === categoryId);
        if (result) return [result];
        return [];
      },
    },
  });
};
